<template>
    <main>
        <ButtonGroup shape="circle">
            <Button icon="md-refresh" @click="loadData">刷新</Button>
            <Button icon="md-add-circle" @click="create" v-if="Auth([ 'Admin', 'create' ])">添加</Button>
        </ButtonGroup>

        <Divider />
        <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>
        
        <Divider />
        <pagination ref="pagination" :total="total" @on-change="loadData"/>
    </main>
</template>

<script>
    export default {
        data()
        {
            return {
                sort   : '',
                total  : 0,
                data   : [],
                columns: [
                    {
                        title   : 'ID',
                        key     : 'id',
                        sortable: 'custom',
                        minWidth: 80
                    },
                    {
                        title   : '用户名',
                        key     : 'username',
                        sortable: 'custom',
                        minWidth: 250
                    },
                    {
                        title   : '角色',
                        minWidth: 120,
                        key     : 'role',
                        sortable: 'custom',
                        render  : (h, params) => {
                            if (params.row.role_id)
                                return h('span', params.row.role.title)
                            else
                                return h('span', { class: 'text-red' }, '超级管理员')
                        }
                    },
                    {
                        title   : '最后登录时间',
                        minWidth: 120,
                        key     : 'logged_at',
                        sortable: 'custom',
                        render  : (h, params) => {
                            return h('span', params.row.logged_at ? params.row.logged_at : '-')
                        }
                    },
                    {
                        title   : '状态',
                        minWidth: 90,
                        key     : 'status',
                        sortable: 'custom',
                        render  : (h, params) => {
                            return h('Icon', {
                                props: {
                                    type: params.row.status ? 'ios-checkmark-circle' : 'ios-remove-circle'
                                },
                                class: params.row.status ? 'text-green' : 'text-red'
                            })
                        }
                    },
                    {
                        title   : '操作',
                        minWidth: 110,
                        align   : 'center',
                        render  : (h, params) => {
                            var html = []

                            if (this.Auth([ 'Admin', 'update' ]))
                            {
                                html.push(h('Button', {
                                    props: {
                                        type: 'success',
                                        size: 'small',
                                        icon: 'md-create'
                                    },
                                    class: 'mg-lr-xs',
                                    on: {
                                        click: () => {
                                            this.update(params.row)
                                        }
                                    }
                                }))
                            }

                            if (this.Auth([ 'Admin', 'delete' ]))
                            {
                                html.push(h('Button', {
                                    props: {
                                        type: 'error',
                                        size: 'small',
                                        icon: 'md-trash'
                                    },
                                    class: 'mg-lr-xs',
                                    on: {
                                        click: () => {
                                            this.remove(params.row)
                                        }
                                    }
                                }))
                            }

                            return h('div', html)
                        }
                    }
                ]
            }
        },
        mounted()
        {
            this.loadData()
        },
        methods: {
            loadData()
            {
                let params = {
                    sort : this.sort,
                    page : this.$refs.pagination.page,
                    limit: this.$refs.pagination.limit
                }

                this.$http.get('/admin/list', { params: params })
                    .then(res => {
                        this.total = res.total
                        this.data  = res.data
                    })
            },
            changeSort(item)
            {
                this.sort = item.order == 'normal' ? '' : item.key + ',' + item.order
                this.$refs.pagination.page = 1
                this.loadData()
            },
            create()
            {
                this.$router.push('/admin/create')
            },
            update(item)
            {
                this.$router.push('/admin/update/' + item.id)
            },
            remove(item)
            {
                this.$Modal.confirm({
                    title  : '确定要删除吗？',
                    content: item.username,
                    onOk   : () => {
                        this.$http.post('/admin/delete/id/' + item.id)
                            .then(res => {
                                this.loadData()
                            })
                    }
                })
            }
        }
    }
</script>